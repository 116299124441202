<template>
  <div class="c-row c-horizontal-center">
    <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
      <!-- Título Meus dados -->
      <div class="c-row ui-mb-4">
        <div class="c-col-full c-d-flex">
          <div class="ui-flex-1 c-d-flex c-text-h3">
            <c-skeleton-block
              v-if="loading"
              width="10rem"
            />
            <span v-else>
              Meus dados
            </span>
          </div>
          <!-- Botões -->
          <div class="c-d-flex sm:ui-justify-end ui-flex-row-reverse sm:ui-flex-row">
            <div class="c-d-flex c-vertical-end">
              <div
                v-if="loading"
                class="c-d-flex ui-h-4 ui-space-x-2"
              >
                <c-skeleton-block width="7rem" />
                <c-skeleton-block width="7rem" />
              </div>
              <router-link
                v-else
                :to="{ name: 'alterar-dados' }"
                data-cy="doChangePersonalDataLink"
              >
                <c-link
                  class="c-text-gray-300 c-d-flex"
                  component="button"
                >
                  <icon
                    name="Edit"
                    color="var(--c-color-gray-300)"
                  />
                  <!-- Na versão mobile o texto do botão deve ser encurtado -->
                  <span class="sm:c-d-none">Dados</span>
                  <span class="c-d-none sm:c-d-flex">Alterar dados</span>
                </c-link>
              </router-link>

              <router-link
                v-if="user && user.id"
                :to="{ name: 'alterar-senha' }"
                data-cy="doChangePasswordLink"
              >
                <c-link
                  class="c-text-gray-300 c-d-flex"
                  component="button"
                >
                  <icon
                    name="Edit"
                    color="var(--c-color-gray-300)"
                  />
                  <!-- Na versão mobile o texto do botão deve ser encurtado -->
                  <span class="sm:c-d-none">Senha</span>
                  <span class="c-d-none sm:c-d-flex">
                    {{ user.hasPassword ? 'Alterar senha' : 'Cadastrar senha' }}
                  </span>
                </c-link>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Card Meus dados -->
      <div class="c-row ui-mb-8">
        <div class="c-col-full">
          <card>
            <!-- Dados -->
            <div class="c-row">
              <!-- Coluna da esquerda -->
              <div class="c-col-full sm:c-col">
                <!-- Nome completo -->
                <div class="c-row ui-mb-4">
                  <div class="c-col-full">
                    <c-skeleton-block
                      v-if="loading"
                      height="1rem"
                      width="12rem"
                    />
                    <div
                      v-else
                      class="c-text-gray c-text-b2"
                    >
                      Nome completo
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="16rem"
                    />
                    <div
                      data-cy="nameLabel"
                      class="c-text-b1"
                      v-else
                    >
                      {{ user.name }}
                    </div>
                  </div>
                </div>

                <!-- Email -->
                <div class="c-row ui-mb-4">
                  <div class="c-col-full">
                    <c-skeleton-block
                      v-if="loading"
                      height="1rem"
                      width="6rem"
                    />
                    <div
                      v-else
                      class="c-text-gray c-text-b2"
                    >
                      Email de acesso
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="14rem"
                    />
                    <div
                      class="c-text-b1 ui-break-words"
                      data-cy="emailLabel"
                      v-else
                    >
                      {{ user.email }}
                    </div>
                  </div>
                </div>

                <div class="c-row ui-mb-4">
                  <div class="c-col-full">
                    <c-skeleton-block
                      v-if="loading"
                      height="1rem"
                      width="10rem"
                    />
                    <div
                      v-else
                      class="c-text-gray c-text-b2"
                    >
                      Data de nascimento
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="8rem"
                    />
                    <div
                      v-else
                      class="c-text-b1"
                    >
                      {{ user.birthday | birthday }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Coluna da direita -->
              <div class="c-col-full sm:c-col">
                <!-- CPF -->
                <div class="c-row ui-mb-4">
                  <div class="c-col-full">
                    <c-skeleton-block
                      v-if="loading"
                      height="1rem"
                      width="4rem"
                    />
                    <div
                      v-else
                      class="c-text-gray c-text-b2"
                    >
                      CPF
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="10rem"
                    />
                    <div
                      class="c-text-b1"
                      data-cy="cpfLabel"
                      v-else
                    >
                      <span>{{ user.cpf | truncatedCPF }}</span>
                      <span
                        v-if="!user.cpf"
                        class="c-text-red-400"
                      >
                        Não informado
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Telefone -->
                <div class="c-row ui-mb-4">
                  <div class="c-col-full">
                    <c-skeleton-block
                      v-if="loading"
                      height="1rem"
                      width="12rem"
                    />
                    <div
                      v-else
                      class="c-text-gray c-text-b2"
                    >
                      Telefone ou celular
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="10rem"
                    />
                    <div
                      class="c-text-b1"
                      data-cy="phoneLabel"
                      v-else
                    >
                      {{ user.phone | formatPhone }}
                      <span
                        v-if="!user.phone"
                        class="c-text-red-400"
                      >
                        Não informado
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- Título Endereço -->
      <div class="c-row">
        <div class="c-col-full ui-mb-4 c-d-flex c-vertical-end">
          <div class="c-text-h3 ui-flex-1">
            <c-skeleton-block
              v-if="loading"
              width="8rem"
            />
            <span v-else>
              Endereço
            </span>
          </div>
          <!-- Botão Alterar endereço -->
          <div
            v-if="loading"
            class="c-d-flex ui-h-4 ui-space-x-2"
          >
            <c-skeleton-circle size="1rem" />
            <c-skeleton-block width="6rem" />
          </div>
          <router-link
            v-else
            :to="{ name: 'alterar-endereco' }"
            data-cy="doChangeAddressLink"
          >
            <c-link
              class="c-text-gray-300 c-d-flex"
              component="button"
            >
              <icon
                name="Edit"
                color="var(--c-color-gray-300)"
              />
              Alterar endereço
            </c-link>
          </router-link>
        </div>
      </div>

      <!-- Card Endereço -->
      <div class="c-row">
        <div class="c-col-full">
          <card>
            <div class="c-row">
              <div class="c-col">
                <!-- Endereço -->
                <div class="c-row ui-mb-4">
                  <div
                    v-if="loading || user.address.zipCode"
                    class="c-col"
                  >
                    <c-skeleton-block
                      v-if="loading"
                      height="1rem"
                      width="12rem"
                    />
                    <div
                      class="c-text-b1"
                      data-cy="numberLabel"
                      v-else
                    >
                      {{ user.address.streetName }} - {{ user.address.number }}
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="8rem"
                    />
                    <div
                      class="c-text-b2 ui-break-words"
                      data-cy="complementLabel"
                      v-else
                    >
                      {{ user.address.complement }}
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="8rem"
                    />
                    <div
                      class="c-text-b2"
                      data-cy="neighborhoodLabel"
                      v-else
                    >
                      {{ user.address.neighborhood }}
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="6rem"
                    />
                    <div
                      class="c-text-b2"
                      data-cy="stateLabel"
                      v-else
                    >
                      {{ user.address.city }} - {{ user.address.state }}
                    </div>

                    <c-skeleton-block
                      v-if="loading"
                      class="ui-mt-1"
                      height="1rem"
                      width="8rem"
                    />
                    <div
                      class="c-text-b2"
                      data-cy="zipCodeLabel"
                      v-else
                    >
                      CEP {{ user.address.zipCode | formatCEP }}
                    </div>
                  </div>
                  <div
                    v-else
                    class="c-col"
                  >
                    <span class="c-text-b1 c-text-red-400">Não informado</span>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Icon, CLink, CSkeletonBlock, CSkeletonCircle } from '@estrategiahq/coruja-web-ui';
import Card from '@/components/card';
import { GlobalStoreKeys } from '@/store/modules/global';
import { UserStoreKeys } from '@/store/modules/user';
import { createMask } from 'imask';

export default {
  name: 'MeusDadosPage',
  components: {
    Card,
    Icon,
    CLink,
    CSkeletonBlock,
    CSkeletonCircle,
  },
  computed: {
    ...mapGetters({
      user: UserStoreKeys.GET_LOGGED_USER,
      loading: GlobalStoreKeys.GET_PAGE_LOADING,
    }),
  },
  filters: {
    birthday(val) {
      if (!val) {
        return '--/--/----';
      }
      return val.split('-').reverse().join('/');
    },
    truncatedCPF(cpf) {
      if (!cpf) {
        return;
      }
      cpf = cpf.padStart(11, '0');
      cpf = cpf.slice(cpf.length - 5);
      const masked = createMask({ mask: '•••.•••.000-00' });
      return masked.resolve(cpf);
    },
    formatPhone(phone) {
      if (!phone) {
        return;
      }
      const masked = createMask({ mask: [
        { mask: '(00) 0000-0000' },
        { mask: '(00) 0 0000-0000' },
      ] });
      return masked.resolve(phone);
    },
    formatCEP(cep) {
      if (!cep) {
        return;
      }
      const masked = createMask({ mask: '00000-000' });
      return masked.resolve(cep);
    },
  },
};
</script>
